export const environment = {
  name: 'stg',
  production: true,
  enablePendo: false,
  enableDatadog: false,
  enableServiceWorker: true,
  enableGoogleAnalytics: false,
  enableFullStory: false,
  applicationUrl: 'https://mims.rewaatech.com',
  webSocketUrl: 'wss://62ssjx6o4a.execute-api.ap-south-1.amazonaws.com/stg',
  fullViewClientId: '7b875b58-d66b-438a-86bd-873cc9db7501',
  sallaAppClientId: '94899be59e0c3be302e60ddb295595b2',
  zidAppClientId: '529',
  bonatAppClientId: 'q9lbrf2oaogk0ujokrcqk2vpm',
  bonatRedirectUrl: 'https://stg-api.bonat.io/rewaa/install',
  cognitoAuthUrl: 'https://rewaa.auth.ap-south-1.amazoncognito.com/login',
  userPoolId: 'ap-south-1_VRlpO2PzB',
  clientId: '2oqq000vpn3olddsk42ftj2gnf',
  bonatServiceToken: '',
  s3InventoryBucket:
    'https://s3.amazonaws.com/stg-mims-service-any-item-image.mumbai/inventory/products/',
  defaultImage: 'default.png',
  enableHttp: true,
  enableIntercom: true,
  tapPublicKey: 'pk_test_jMgIW1ilDhXYSNPQ6BKob8aV',
  s3Bucket: 'https://s3.ap-south-1.amazonaws.com/mumbai.mims.rewaatech.com/',
  offline_intervals: { product: 2160000, customer: 2160000 },
  offline_config: {
    invoice_limit: { android: 250, others: 300 },
  },
  enableUserGuiding: false,
  enableUserPilot: false,
  launchDarklyClientSideId: '63566d3ef1d7751181713514',
  mockLaunchDarkly: true,
  enableAutomationTestFF: true,
  automationTestFFAPIKey: '260b4c17-e0de-4323-927e-618109cb518e',
  pluginUrl: 'http://localhost:5000',
  accountingApiPath: '/api/accounting',
  onboardingApiPath: '/api/onboarding',
  expenseApiPath: '/api/expense-service',
  customFieldApiPath: '/api/custom-field-service',
  firebaseConfig: {
    apiKey: 'AIzaSyDpqm3hlLPwJ5mZmyX_GuGfth1lRWB1fHo',
    authDomain: 'rewaa-test.firebaseapp.com',
    projectId: 'rewaa-test',
    storageBucket: 'rewaa-test.appspot.com',
    messagingSenderId: '181952186125',
    appId: '1:181952186125:web:d9530938a18589a82f9244',
    measurementId: 'G-6XEQD0RRJV',
  },
  intercomAppId: 'sg0ry903',
  enableFirestore: true,
  allowAliasedEmails: true,
  reportDataLimit: 10000,
  /**
   * @note Moving this to lambda function URL instead of load balancer URL
   * due to 1MB response size limit @see https://docs.aws.amazon.com/elasticloadbalancing/latest/application/lambda-functions.html
   */
  reportingApiUrl: 'https://mims.rewaatech.com/api/report-server/transformer',
  dashboardApiUrl:
    'https://7rdhsmoyjv6crjvdbggypxf5fu0glqvc.lambda-url.ap-south-1.on.aws',
  appVersion: require('../../package.json').version + '-staging',
  nearpayEnvironment: 'sandbox',
  launchDarklyBaseUrl: undefined,
  imexUrl: 'https://f46flfc5x7.execute-api.ap-south-1.amazonaws.com/stg/imex',
  rewaaHrUrl: 'https://hr.rewaatech.com/account/login?action=CognitoSignIn',
};
